<template>
  <Toast />

  <DataTable :value="manufactureData" :show-gridlines="true" responsiveLayout="scroll" :loading="loading">
    <template v-if="mode == 'single'">
      <Column field="period" :style="{ 'min-width': '80px', 'width': '80px' }">
        <template #body="{ data }">
          {{ data.period[0] }} - {{ data.period[1] }}
        </template>
      </Column>
      <!-- Loop manufacturableProducts -->
      <Column v-for="product in manufacturableProducts" :key="product.product_id" :field="product.name"
        :style="{ 'min-width': '110px', 'width': '120px' }">
        <template #header>
          <div class="flex flex-column align-items-center">
            <p>{{ product.name }}</p>
            <Button v-if="isSameDay(dayjs(), dayjs(startDate))" icon="pi pi-plus"
              class="p-button-rounded-sm p-button-purpply" @click="selectNewProduct(product)"></Button>
          </div>
        </template>
        <template #body="{ data, field }">
          <div class="flex flex-column align-items-center">
            <template v-for="d in data[field]" :key="d.id">
              <p v-if="d.hasOwnProperty('sum')">{{ Math.round(d.sum) }} {{ d.uom }}</p>
              <div v-else class="flex">
                <Button
                  :label="`${formatDate(d.manufacture_datetime, 'HH:mm')} ${d.product_out_quantity} ${d.product_out_uom} (${getPropertiesType(d, true)})`"
                  class="p-1 p-button-text" :class="typeClass(getPropertiesType(d))"
                  :disabled="getPropertiesType(d) == 'merged' || getPropertiesType(d) == 'new_merge' || !isLastProduct(d)"
                  @click="selectMergeProduct(d)"></Button>
                <Button v-show="isSameDay(dayjs(), dayjs(startDate))" icon="pi pi-pencil" class="p-button-text"
                  @click="selectUpdateProduct(d)" />
              </div>
            </template>
          </div>
        </template>
      </Column>
    </template>
    <template v-else-if="mode == 'range'">
      <Column field="date" :style="{ 'min-width': '100px', 'width': '100px' }">
        <template #body="{ data }">
          {{ (data.date instanceof Date) ? formatDate(data.date, "YYYY-MM-DD") : data.date }}
        </template>
      </Column>
      <Column v-for="product in manufacturableProducts" :key="product.product_id" :field="product.name"
        :style="{ 'min-width': '110px', 'width': '120px' }">
        <template #header>
          <div class="flex flex-column align-items-center">
            <p>{{ product.name }}</p>
          </div>
        </template>
        <template #body="{ data, field }">
          <!-- {{ data[field] }} -->
          <div class="flex justify-content-center align-items-center gap-1">
            <template v-for="d in data[field]" :key="d.id">
              <span>{{ d }}</span>
            </template>
          </div>
        </template>
      </Column>
    </template>
  </DataTable>

  <!-- 新茶 Dialog -->
  <Dialog header="請選擇使用數量" v-model:visible="showNewProduct" :dismissableMask="true" :breakpoints="{ '960px': '96vw' }"
    :style="{ width: '70vw' }" :modal="true" @hide="cleanUp">
    <div class="my-4 flex gap-2">
      <SelectButton v-model="selectedQuantity" :options="quantityOptions" optionLabel="text" />
      <div v-if="selectedQuantity != null && selectedQuantity.text == '自訂'" class="flex">
        <InputNumber v-model="selectedQuantity.value" :inputProps="{ 'type': 'tel' }" />
        <span class="p-inputgroup-addon">g</span>
      </div>
    </div>
    <template #footer>
      <Button class="p-button-secondary" label="新茶" :disabled="selectedQuantity == undefined" @click="submitNewList" />
    </template>
  </Dialog>

  <!-- 混茶 Dialog -->
  <Dialog header="請選擇使用數量" v-model:visible="showMergeProduct" :dismissableMask="true" :breakpoints="{ '960px': '96vw' }"
    :style="{ width: '70vw' }" :modal="true" @hide="cleanUp">
    混 {{ formatDate(selectedMergeProduct.manufacture_datetime, 'HH:mm') }}
    {{ selectedMergeProduct.product_out_quantity }} {{ selectedMergeProduct.product_out_uom }} {{ getPropertiesType(d,
        true)
    }}
    <div class="my-4 flex gap-2">
      <SelectButton v-model="selectedQuantity" :options="quantityOptions" optionLabel="text" />
      <div v-if="selectedQuantity != null && selectedQuantity.text == '自訂'" class="flex">
        <InputNumber v-model="selectedQuantity.value" :inputProps="{ 'type': 'tel' }" />
        <span class="p-inputgroup-addon">g</span>
      </div>
    </div>
    <template #footer>
      <Button class="p-button-secondary" label="混茶" @click="submitMergeList" />
    </template>
  </Dialog>

  <!-- 更新 Dialog -->
  <Dialog header="更新數量" v-model:visible="showUpdateProduct" :dismissableMask="true" :breakpoints="{ '960px': '96vw' }"
    :style="{ width: '70vw' }" :modal="true" @hide="cleanUp">
    <!-- {{ selectedUpdateProduct }} -->
    <p class="mt-4">當前紀錄: {{ selectedUpdateProduct.product_out_name }} {{ selectedUpdateProduct.product_out_quantity }}
      {{ selectedUpdateProduct.product_out_uom }}</p>
    <div class="my-2 flex">
      <InputNumber v-model="inputUpdateQunatity" :inputProps="{ 'type': 'tel' }" @input="onInputUpdate" />
      <span class="p-inputgroup-addon">{{ selectedUpdateProduct.product_out_uom }}</span>
    </div>
    <hr>
    <div class="my-4 flex gap-2">
      <SelectButton v-model="inputUpdate" :options="quantityOptions" optionLabel="text" @change="onInputUpdate" />
      <div v-if="inputUpdate != null && inputUpdate.text == '自訂'" class="flex">
        <InputNumber v-model="inputUpdate.value" :inputProps="{ 'type': 'tel' }" />
        <span class="p-inputgroup-addon">g</span>
      </div>
    </div>
    更新為 {{ aboutUpdateAmount }} {{ selectedUpdateProduct.product_out_uom }}
    <template #footer>
      <Button class="p-button-secondary" label="更新" @click="submitUpdate" :disabled="(aboutUpdateAmount == 0)" />
    </template>
  </Dialog>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import dayjs from 'dayjs'
import { useToast } from 'primevue/usetoast'
import { formatDate, isSameDay, listDates, dayAgo } from '@/composable/date'
import {
  getOverviewData,
  getGroupedOverviewData,
  getManufacturableProducts,
  postManufactorProducts,
  postConfirmManufacture,
  postTakeInventory,
  postJoinLots,
  postLotProperties,
  postManufactureProperties,
  postCancelManufactureOrder,
} from '@/api'
import { deepClone } from '@/helpers'

const props = defineProps(['startDate', 'endDate', 'mode'])

const toast = useToast()

const loading = ref(false)
const defaultManuTable = ref({})
const properyTypes = ref({})
const rangeDateList = ref([])
const defaultManuData = ref({})
const manufactureOrders = ref([])
const manufacturableProducts = ref([])
const inventory = ref([])
const showNewProduct = ref(false)
const showMergeProduct = ref(false)
const showUpdateProduct = ref(false)
const selectedNewProduct = ref({})
const selectedMergeProduct = ref({})
const selectedUpdateProduct = ref({})
const selectedQuantity = ref(null)
const inputUpdateQunatity = ref(null)
const inputUpdate = ref(null)
const quantityOptions = ref([
  { text: '50g', value: 50 },
  { text: '60g', value: 60 },
  { text: '80g', value: 80 },
  { text: '100g', value: 100 },
  { text: '120g', value: 120 },
  { text: '160g', value: 160 },
  { text: '200g', value: 200 },
  { text: '自訂', value: null },
])

const manufactureData = computed(() => {
  if (props.mode == 'range') {
    let totalG = { date: '總計' }
    let list = _.cloneDeep(rangeDateList.value)
    let totalList = [..._.cloneDeep(rangeDateList.value), totalG]
    manufactureOrders.value.forEach(invs => {
      let diffDay = 0
      invs.orders.forEach(inv => {
        diffDay = dayAgo(dayjs(inv.manufacture_datetime), dayjs(props.startDate))
        if (!Object.keys(list[diffDay]).includes(inv.product_out_name)) {
          list[diffDay][inv.product_out_name] = [inv]
        } else {
          list[diffDay][inv.product_out_name].push(inv)
        }
      })
      // console.log('now list', diffDay, ':', list[diffDay])
      Object.keys(list[diffDay]).forEach(key => {
        if (key == 'date') {
          totalList[diffDay][key] = list[diffDay][key]
        } else {
          let sum = _.sumBy(list[diffDay][key], 'product_out_quantity')
          totalList[diffDay][key] = { sum: sum, uom: list[diffDay][key][0]['product_out_uom'] }

          totalG[key] = _.get(totalG, key, { sum: 0, uom: 'g' })
          totalG[key].sum += sum
          // console.log('key:', key, 'sum', sum)
        }
      })
      // console.log('totalList', totalList[diffDay])
    })
    // console.log('totalList', totalList)

    Object.keys(totalG).forEach(key => {
      if (key !== 'date') {
        const sum = totalG[key].sum
        const ratio = defaultManuTable.value[key]
        totalG[key].sum = _.round(sum / ratio, 2)
      }
    })

    return totalList
  } else {
    let list = deepClone(defaultManuData.value)
    let totalML = { period: ['總計', ''] }
    let totalG = { period: ['總計', ''] }
    if (Array.isArray(list)) {
      list.push(totalML)
      list.push(totalG)
    }
    manufactureOrders.value.forEach(inv => {
      let periodIdx = Math.floor((dayjs(inv.manufacture_datetime).hour() - 8) / 2)
      // console.log('manufactureData periodIdx', periodIdx)
      // 依照[列]分類，Array [period 1, period 2 ...]
      if (periodIdx >= 0 && periodIdx < 8) {
        if (!Object.keys(list[periodIdx]).includes(inv.product_out_name)) {
          list[periodIdx][inv.product_out_name] = [inv]
          // console.log('manufactureData periodIdx', list[periodIdx])
        } else {
          list[periodIdx][inv.product_out_name].push(inv)
          // console.log('manufactureData periodIdx', list[periodIdx])
        }
      }

      // 總計ml數量
      if (inv.product_out_name in totalML) {
        totalML[inv.product_out_name][0].sum += inv.product_out_quantity
      } else {
        totalML[inv.product_out_name] = [{ sum: inv.product_out_quantity, uom: 'mL' }]
      }
      // 總計g數量
      let sumG = inv.product_consumed.reduce(
        (sum, p) => p.uom === 'g' ? sum + p.quantity : sum,
        0,
      )
      if (inv.product_out_name in totalG) {
        totalG[inv.product_out_name][0].sum += sumG
      } else {
        totalG[inv.product_out_name] = [{ sum: sumG, uom: 'g' }]
      }
    })
    return list
  }
})
const aboutUpdateAmount = computed(() => {
  let amount = 0

  if (inputUpdateQunatity.value != null) {
    amount = inputUpdateQunatity.value
  } else if (inputUpdate.value != null) {
    amount = defaultManuTable.value[selectedUpdateProduct.value.product_out_name] * inputUpdate.value.value
  } else { }
  // console.log('aboutUpdateAmount', amount)

  return amount
})

watch(() => props.startDate, (newVal, oldVal) => {
  fetchTableContents().then(() => { })
})
watch(() => props.endDate, (newVal, oldVal) => {
  fetchTableContents().then(() => { })
})
watch(() => props.mode, (newVal, oldVal) => {
  fetchTableContents()
})

const getManufactureTable = async () => {
  defaultManuTable.value = await fetch('data/manufactureTable.json').then(res => res.json()).then(d => d)
}
const getPropertyTypes = async () => {
  properyTypes.value = await fetch('data/properyTypes.json').then(res => res.json()).then(d => d)
}
const getDefaultData = async () => {
  defaultManuData.value = await fetch('data/defaultPeriods.json').then(res => res.json()).then(d => d)
}
const getManufactorData = async () => {
  let resp = await getManufacturableProducts()
  manufacturableProducts.value = resp.data.products
    .filter((prod) => prod.category == '茶')
    .sort((a, b) => a.product_id - b.product_id)
}
const getInventoryData = async () => {
  let resp = await getManufacturableProducts()
  inventory.value = resp.data.products.filter((prod) => prod.category == '茶').sort((a, b) => a.product_id - b.product_id)
}
const fetchTableContents = async () => {
  loading.value = true
  rangeDateList.value = listDates(props.startDate, props.endDate).map((date) => ({ date: date }))
  // console.log('rangeDateList.value', rangeDateList.value)
  await getManufactorData()
  await getInventoryData()

  try {
    let resp
    if (props.mode == 'range') {
      resp = await getGroupedOverviewData(
        dayjs(props.startDate).startOf('day').format("YYYY-MM-DD HH:mm:ss"),
        dayjs(props.endDate).endOf('day').format("YYYY-MM-DD HH:mm:ss")
      )
    } else {
      resp = await getOverviewData(
        dayjs(props.startDate).startOf('day').format("YYYY-MM-DD HH:mm:ss"),
        dayjs(props.startDate).endOf('day').format("YYYY-MM-DD HH:mm:ss")
      )
    }

    manufactureOrders.value = resp.data.manufacture_orders
  } catch (error) {
    console.log('error', error)
  }
  loading.value = false
}
// 新茶製作
const submitNewList = async () => {
  let outQuantity = defaultManuTable.value[selectedNewProduct.value.name] * selectedQuantity.value.value
  await postManufactorProducts([
    {
      product_id: selectedNewProduct.value.product_id,
      quantity: outQuantity
    }
  ]).then((resp) => {
    let manu = resp.data.manufacture_orders[0]
    manu.product_required.sort((a, b) => b.product_id - a.product_id)
    return postConfirmManufacture([
      {
        order_id: manu.order_id,
        product_in: [
          {
            product_id: manu.product_required[0].product_id,
            lot_id: manu.product_required[0].lots[0].id,
            quantity: manu.product_required[0].quantity,
          }
        ]
      }
    ])
  }).then((result) => {
    showNewProduct.value = false
    if (result.status == 200) {
      toast.add({ severity: 'success', summary: '成功', detail: '新茶製造成功', life: 2000 })
      setTimeout(async () => {
        await postLotProperties(result.data.manufacture_orders[0].lot_id, { type: "new" })
        await postManufactureProperties(result.data.manufacture_orders[0].order_id, { type: "new" })
        await fetchTableContents()
      }, 1000)
    }
  }).catch((err) => {
    console.log('submitNewList error', err)
    toast.add({ severity: 'error', summary: '失敗', detail: '新茶製造失敗', life: 2000 })
    showNewProduct.value = false
  })
}
// 混茶製作
const submitMergeList = async () => {
  // 先更新資料，並確認混茶對象是否為最新茶
  await fetchTableContents();
  if (!isLastProduct(selectedMergeProduct.value)) {
    showMergeProduct.value = false;
    toast.add({ severity: 'error', summary: '失敗', detail: '舊茶不可混茶', life: 2000 })
    return
  }

  let outQuantity = defaultManuTable.value[selectedMergeProduct.value.product_out_name] * selectedQuantity.value.value
  await postManufactorProducts([
    {
      product_id: selectedMergeProduct.value.product_out_id,
      quantity: outQuantity
    }
  ]).then((resp) => {
    let manu = resp.data.manufacture_orders[0]
    manu.product_required.sort((a, b) => b.product_id - a.product_id)
    // console.log('postConfirmManufacture', manu.product_required[0])
    return postConfirmManufacture([
      {
        order_id: manu.order_id,
        product_in: [
          {
            product_id: manu.product_required[0].product_id,
            lot_id: manu.product_required[0].lots[0].id,
            quantity: manu.product_required[0].quantity,
          }
        ]
      }
    ])
  }).then((result) => {
    showMergeProduct.value = false
    if (result.status == 200) {
      toast.add({ severity: 'success', summary: '成功', detail: '混茶成功', life: 2000 })
      setTimeout(async () => {
        await postJoinLots(selectedMergeProduct.value.lot, result.data.manufacture_orders[0].lot_id)
        await postTakeInventory([
          {
            product_id: selectedMergeProduct.value.product_out_id,
            lot_id: selectedMergeProduct.value.lot,
            quantity: 0
          }
        ])
        await postLotProperties(selectedMergeProduct.value.lot, { type: "merged" })
        await postManufactureProperties(selectedMergeProduct.value.id, { type: "merged" })
        await postLotProperties(result.data.manufacture_orders[0].lot_id, { type: "new_merge" })
        await postManufactureProperties(result.data.manufacture_orders[0].order_id, { type: "new_merge" })
        await fetchTableContents()
      }, 1000)
    }
  }).catch((err) => {
    console.log('submitMergeList error', err)
    showMergeProduct.value = false
    toast.add({ severity: 'error', summary: '失敗', detail: '混茶失敗', life: 2000 })
  })
}
// 更新
const submitUpdate = async () => {
  // 1. Call cancel_manufacture_order endpoint
  let resp = await postCancelManufactureOrder(selectedUpdateProduct.value.id)

  // 2. Update Lot properties
  let lastProps = JSON.parse(selectedUpdateProduct.value.properties)
  await postLotProperties(selectedUpdateProduct.value.lot, { ...lastProps, status: 'cancelled' })
  await postManufactureProperties(selectedUpdateProduct.value.id, { ...lastProps, status: 'cancelled' })

  // 3. Create a new manufacture order
  postManufactorProducts([
    {
      product_id: selectedUpdateProduct.value.product_out_id,
      quantity: aboutUpdateAmount.value
    }
  ]).then((resp) => {
    let manu = resp.data.manufacture_orders[0]
    manu.product_required.sort((a, b) => b.product_id - a.product_id)
    // 4. Confirm the manufacture order with create_datetime in payload
    return postConfirmManufacture([
      {
        order_id: manu.order_id,
        product_in: [
          {
            product_id: manu.product_required[0].product_id,
            lot_id: manu.product_required[0].lots[0].id,
            quantity: manu.product_required[0].quantity,
          }
        ],
      },
    ], selectedUpdateProduct.value.manufacture_datetime)
  }).then((result) => {
    showUpdateProduct.value = false
    if (result.status == 200) {
      toast.add({ severity: 'success', summary: '成功', detail: '更新成功', life: 2000 })
      setTimeout(async () => {
        await postLotProperties(result.data.manufacture_orders[0].lot_id, lastProps)
        await postManufactureProperties(result.data.manufacture_orders[0].order_id, lastProps)
        await fetchTableContents()
      }, 1000)
    }
  }).catch((err) => {
    console.log('submitUpdate error', err)
    toast.add({ severity: 'error', summary: '失敗', detail: '更新失敗', life: 2000 })
    showUpdateProduct.value = false
  })
}
const getPropertiesType = (obj, mapping = false) => {
  try {
    if (mapping) return properyTypes.value[JSON.parse(obj.properties)["type"]]
    else return JSON.parse(obj.properties)["type"]
  } catch (error) {
    return ""
  }
}
const isLastProduct = (obj) => {
  if (!isSameDay(dayjs(), dayjs(props.startDate))) return false

  const sameProducts = manufactureOrders.value.filter(inv => inv.product_out_name === obj.product_out_name)
  const lastProduct = sameProducts[sameProducts.length - 1];
  return lastProduct.id === obj.id
}
const typeClass = (type) => {
  switch (type) {
    case "new":
      return "p-button-info"
    case "new_merge":
      return "p-button-secondary"
    case "merged":
      return "p-button-secondary"
    default:
      return "p-button-secondary"
  }
}
const selectNewProduct = (prod) => {
  showNewProduct.value = true
  selectedNewProduct.value = { ...prod }
}
const selectMergeProduct = (prod_out) => {
  showMergeProduct.value = true
  selectedMergeProduct.value = { ...prod_out }
}
const selectUpdateProduct = (prod) => {
  showUpdateProduct.value = true
  selectedUpdateProduct.value = { ...prod }
}
const onInputUpdate = (evt) => {
  if (Number.isInteger(evt.value)) {
    inputUpdate.value = null
    inputUpdateQunatity.value = evt.value
  } else {
    inputUpdateQunatity.value = null
  }
}
const cleanUp = () => {
  inputUpdateQunatity.value = null
  inputUpdate.value = null
  if (selectedQuantity.value.text == '自訂') {
    selectedQuantity.value.value = null
  }
  selectedQuantity.value = null
  selectedUpdateProduct.value = {}
}

//
getManufactureTable()
getPropertyTypes()
getDefaultData()

onMounted(async () => {
  await fetchTableContents()
})
</script>

<style lang="scss" scoped>
.p-button-rounded-sm {
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
}

.p-disabled {
  opacity: 0.8 !important;
}
</style>
